// Slick slider implementation
// @doc http://kenwheeler.github.io/slick/
import 'slick-carousel';

$( function () {
    let $carousel = $( '#partnerCarousel, .inspiration-carousel' );
    if ($carousel.length > 0) {
        $carousel.slick( {
            dots: true,
            infinite: true,
            autoplay: true,
            speed: 500,
            slidesToShow: 4,
            slidesToScroll: 2,
            prevArrow: '<button class="slick-arrow" aria-label="Previous" type="button"><span class="fas fa-chevron-left fa-4x slick-prev"></span></button>',
            nextArrow: '<button class="slick-arrow" aria-label="Next" type="button"><span class="fas fa-chevron-right fa-4x slick-next"></span></button>',
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 2,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        });
    }

    let $mainPicCarousel = $( '#mainPictureCarousel' );
    if ($mainPicCarousel.length > 0) {
        $mainPicCarousel.slick( {
            slidesToShow: 1,
            slidesToScroll: 1,
            fade: true,
            asNavFor: '#smallPicturesCarousel',
            dots: false,
            arrows: true,
            prevArrow: '<button class="slick-arrow" aria-label="Previous" type="button"><span class="fas fa-chevron-left fa-4x slick-prev"></span></button>',
            nextArrow: '<button class="slick-arrow" aria-label="Next" type="button"><span class="fas fa-chevron-right fa-4x slick-next"></span></button>',
        });
    }

    let $smallPicCarousel = $( '#smallPicturesCarousel' );
    if ($smallPicCarousel.length > 0) {
        $smallPicCarousel.slick( {
            asNavFor: '#mainPictureCarousel',
            slidesToShow: 3,
            slidesToScroll: 1,
            dots: false,
            centerMode: true,
            focusOnSelect: true,
            slidesMargin: 15,
            arrows: false,
        });
    }

});