// Sticky Tabs for Bootstrap tabs
// to allow for link sharing on specific tab
$( function () {
    
    let hash = window.location.hash;
    hash && $( 'ul.nav a[href="' + hash + '"]' ).tab( 'show' );
  
    $('.nav-pills .nav-link' ).click( function() {
        $( this ).tab( 'show' );
        window.location.hash = this.hash;
    });
  
    // Change tab on hashchange
    window.addEventListener( 'hashchange', function() {
        let changedHash = window.location.hash;
        changedHash && $( 'ul.nav a[href="' + changedHash + '"]' ).tab( 'show' );
    }, false );

});