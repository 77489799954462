$(function () {
  let $togglFormElem = $('.toggl_form'), // elements to toggle
    $form = $('#form_offer'); // form

  //classes/attr to toggle here and there
  let displayClass = 'd-none',
    activeBtn = 'btn-primary',
    btn = 'btn-grey',
    required = 'required';

  // dimension input value
  let $dimension = $('input[name=dimension]'),
    $dimensionStock = $('#product-dimension'),
    $dimensionNoStock = $('#product-dimension-no'),
    $width = $('input[name=width]'),
    $height = $('input[name=height]'),
    $depth = $('input[name=depth]');

  if (!$dimensionStock.parent().parent().hasClass(displayClass)) {
    updateRequiredAttribute(false);
  }

  // quantity input value
  let $quantity = $('input[name=qty]'),
    $qtyStock = $('#product-quantity'),
    $qtyNoStock = $('#product-quantity-no');

  // matiere input value
  let $matiere = $('input[name=matiere]'),
    $matStock = $('#matiere-stock'),
    $matNoStock = $('#product-material');

  // reference input value
  let $reference = $('input[name=reference]'),
    $referenceStock = $('input[name=reference-stock]'),
    $referenceNoStock = $('input[name=reference-no]');

  // post_title input value
  let $postTitle = $('input[name=post_title]'),
    $nameStock = $('input[name=name-stock]'),
    $nameNoStock = $('input[name=name-no]');

  // Handle size input value
  let $handleSizeValue = $('input[name=handle_size]'),
    $handleStock = $('#product-handle'),
    $handleNoStock = $('#product-handle-no');

  // change from stock to no-stock
  $togglFormElem.off('click').on('click', function (e) {
    e.preventDefault();
    if (!$(this).hasClass('no_toggl')) {
      toggleFormDisplay();
    }
  });

  // submit form ajax
  $form.submit(function (e) {
    e.preventDefault();
    let data = $(this).serialize(),
      type = 'POST',
      url = window.ajax_url;
    ajaxCall(url, type, data);
  });

  // toggle display of centimeters inputs
  $dimensionNoStock.off('change').on('change', function () {
    let $selected = $(this).children("option:selected"),
      $toToggle = $('#custom_dimensions');
    if ($selected.hasClass('display_custom')) {
      $toToggle.show();
      updateCustomDimensionValue();
      updateRequiredAttribute();
    } else {
      $toToggle.hide();
      updateRequiredAttribute(false);
    }
    updateInputVal($dimension, $(this).val());
  });

  // ajax function
  function ajaxCall(url, type, data) {
    $.ajax({
      url: url,
      type: type,
      data: data,
    })
      .done(function (response) {
        if (response === 'true') {
          $('#message-ok').removeClass(displayClass);
          $form[0].reset();
        } else {
          $('#message-nok').removeClass(displayClass);
        }
      })
      .fail(function (error) {
        $('#message-nok').removeClass(displayClass);
      });
  }

  // toggle form display between stock and no stock
  function toggleFormDisplay() {
    // change active class on grey/green button
    $togglFormElem.each(function (i, element) {
      element = $(element);
      if (element.hasClass(btn)) {
        element.removeClass(btn).blur();
        element.addClass(activeBtn).focus();
      } else {
        element.removeClass(activeBtn).blur();
        element.addClass(btn);
      }
    });

    // hide and show stoc/no-stock inputs
    $('.toggle_stock').each(function (i, element) {
      element = $(element);
      if (element.hasClass(displayClass)) {
        element.removeClass(displayClass);
        updateIf(element);
      } else {
        element.addClass(displayClass);
      }
    });
  }

  // used when changing from stock to no stock, update special values
  function updateIf(containerElem) {
    let element, value;
    if (containerElem.has($dimensionNoStock).length >= 1) {
      element = $dimension;
      value = $dimensionNoStock.val();
      if ($dimensionNoStock.children("option:selected").hasClass('display_custom')) {
        value = getCustomDimensionVal();
        updateRequiredAttribute();
      } else {
        updateRequiredAttribute(false);
      }
    } else if (containerElem.has($dimensionStock).length >= 1) {
      element = $dimension;
      value = $dimensionStock.val();
      updateRequiredAttribute(false);
    } else if (containerElem.has($qtyNoStock).length >= 1) {
      element = $quantity;
      value = $qtyNoStock.val();
    } else if (containerElem.has($qtyStock).length >= 1) {
      element = $quantity;
      value = $qtyStock.val();
    } else if (containerElem.has($matStock).length >= 1) {
      element = $matiere;
      value = $matStock.val();
    } else if (containerElem.has($matNoStock).length >= 1) {
      element = $matiere;
      value = $matNoStock.val();
    } else if (containerElem.has($referenceStock).length >= 1) {
      element = $reference;
      value = $referenceStock.val();
    } else if (containerElem.has($referenceNoStock).length >= 1) {
      element = $reference;
      value = $referenceNoStock.val();
    } else if (containerElem.has($nameStock).length >= 1) {
      element = $postTitle;
      value = $nameStock.val();
    } else if (containerElem.has($nameNoStock).length >= 1) {
      element = $postTitle;
      value = $nameNoStock.val();
    }
    if (typeof element !== 'undefined') {
      updateInputVal(element, value);
    }
  }

  $dimensionStock.off('change').on('change', function () {
    updateInputVal($dimension, $(this).val());
    updateRequiredAttribute(false);
  });

  $qtyStock.off('change').on('change', function () {
    updateInputVal($quantity, $(this).val());
  });
  $qtyNoStock.off('change').on('change', function () {
    updateInputVal($quantity, $(this).val());
  });

  $matNoStock.off('change').on('change', function () {
    updateInputVal($matiere, $(this).val());
  });

  $width.off('change').on('change', function () {
    updateCustomDimensionValue();
  });
  $height.off('change').on('change', function () {
    updateCustomDimensionValue();
  });
  $depth.off('change').on('change', function () {
    updateCustomDimensionValue();
  });
  $handleStock.off('change').on('change', function () {
    updateInputVal($handleSizeValue, $(this).val());
  });
  $handleNoStock.off('change').on('change', function () {
    updateInputVal($handleSizeValue, $(this).val());
  });


  function updateCustomDimensionValue() {
    $dimension.val(getCustomDimensionVal());
  }

  function updateRequiredAttribute(add = true) {
    if (add === true) {
      $width.attr(required, true);
      $height.attr(required, true);
      $depth.attr(required, true);
    } else {
      $width.removeAttr(required);
      $height.removeAttr(required);
      $depth.removeAttr(required);
    }
  }

  function getCustomDimensionVal() {
    return $dimensionNoStock.val() + ' W: ' + $width.val() + ', H: ' + $height.val() + ', D: ' + $depth.val();
  }

  function updateInputVal(input, value) {
    input.val(value);
  }

});