// CountUp documentation: https://github.com/inorganik/countUp.js/
import { CountUp } from 'countup.js';
import { checkIfVisible } from "../AnimateOnScroll";

$(function () {
    let $countedElements = $( '.counter' );
    let counters = [];

    for ( let i = 0; i < $countedElements.length; i++ ) {
        counters[ i ] = new CountUp ( $countedElements[ i ], $countedElements[ i ].innerHTML, 500 );

        $( window ).on( 'load scroll resize', function () {
            if ( checkIfVisible( $countedElements[ i ] ) ) {
                counters[ i ].start();
            }
        });
    }
});