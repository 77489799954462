$(function () {
  let $sectionProduits = $('.section-products'),
    productImg = '.product-card img',
    productWidth = $(productImg).width(),
    $filters = $('#filters'),
    $searchForm = $('#search-form'),
    $noResult = $('#no-results'),
    location = window.location.href,
    url = new URL((location.indexOf('?') > 0 ? location : location + '?se=&stock=&mat=')),
    params = new URLSearchParams(url.search),
    $triggerDisplay = $('.trigger-display'),
    $displayMats; /* will be used to show matieres */

  // display no results message
  if ($('#products').children().first().children().length === 0) {
    $noResult.show();
  } else {
    $noResult.hide();
    $('.product-card img').css('max-height', $('.product-card').width());
  }

  if ($triggerDisplay.length > 0) {
    $triggerDisplay.each(function(i, el) {
      toggleMatFromCategory($(el).attr('data-key'));
    });
  }

  // filters on matiere
  $filters.on('click', 'a.mat', function (e) {
    reloadContent(e, $(this).attr('href'), $(this).attr('data-val'), $(this).attr('data-ty'));
  });

  // click on category, display matieres linked
  $filters.on('click', 'a.cat_mat', function (e) {
    e.preventDefault();
    toggleMatFromCategory($(this).attr('data-key'));
  });

  // pagination links
  $sectionProduits.on('click', '.pagination a', function (e) {
    reloadContent(e, $(this).attr('href'));
  });
  $sectionProduits.on('change', '#perPage', function(e) {
    reloadContent(e,buildUrl(false, false, false, $(e.currentTarget).val()), $(e.currentTarget).val());
  });
  $searchForm.on('submit', function (e, a) {
    let inputVal = $(this).find('input[name=se]').val(),
      newLink;
    newLink = buildUrl(false, false, inputVal);
    $(this).attr('action', newLink);
    reloadContent(e, newLink, inputVal, 'search');
  });

  function toggleMatFromCategory(category) {
    $displayMats = $('a.'+ category);
    $displayMats.each(function(i, el) {
      if ($(el).hasClass('d-none')) {
        $(el).removeClass('d-none');
      } else {
        $(el).addClass('d-none');
      }
    });
  }

  function reloadContent(e, link, value) {
    e.preventDefault();
    $('#products-container').fadeOut(500, function () {
      $(this).load(link + ' #products', function () {
        window.history.pushState("", document.title, link);
        url = new URL(link);
        params = new URLSearchParams(url.search);
        if ($(this).find('#products').children().first().children().length === 0) {
          $(this).find('#no-results').show();
        } else {
          $(this).find('#no-results').hide();
          // $(this).find(productImg).css('max-height', productWidth);
        }
        updateUrls(value);
        $(this).fadeIn(500);
        $(this).find('.product-card img').css('max-height', $(this).find('.product-card').width());
      });
    });
  }

  function updateUrls(newParam) {
    let $stockUrls = $('.stock');
    $stockUrls.each(function(i, elem) {
      let elemVal = elem.dataset.val;
      elem.href = buildUrl(false, elemVal);
      if (isStockValue(newParam)) {
        if (newParam === elemVal) {
          $(elem).toggleClass('btn-chosen');
        } else {
          $(elem).removeClass('btn-chosen');
        }
      }
    });
    let $matiereUrls = $('.mat');
    $matiereUrls.each(function(i, elem) {
      let elemVal =  elem.dataset.val;
      elem.href = buildUrl(elemVal);
      let $elem = $(elem),
        pMat = null;
      if (!isStockValue(newParam)) {
        pMat = params.get('mat');
        if (pMat !== null && $.inArray(elemVal, pMat.split(',')) >= 0) {
          $elem.addClass('btn-chosen');
        } else {
          $elem.removeClass('btn-chosen');
        }
      }
    });
    $searchForm.attr('action', buildUrl());
  }

  function buildUrl(mat = false, stock = false, search = false, per_page = 9) {
    let param = {'se' : '', 'stock' : '', 'mat' : [], 'per_page': 9};
    let seParams = params.get('se'),
      stockParams = params.get('stock'),
      matParams = params.get('mat'),
      ppParams = params.get('per_page');
    if (seParams !== null && seParams !== '') {
      param.se = seParams;
    }
    if (stockParams !== null  && stockParams !== '') {
      param.stock = stockParams;
    }
    if (matParams !== null && matParams !== '') {
      param.mat = params.get('mat').split(',');
    }
    if (ppParams !== null && ppParams !== '') {
      param.per_page = ppParams;
    }
    if (typeof mat === 'string') {
      let pos = $.inArray(mat, param.mat);
      if (pos < 0) {
        param.mat.push(mat);
      } else {
        param.mat.splice(pos, 1);
      }
    }
    if (typeof stock === 'string') {
      if (stock === param.stock) {
        param.stock = '';
      } else {
        param.stock = stock;
      }
    }
    if (typeof search === 'string') {
      param.se = search;
    }
    if (typeof per_page === 'string') {
      param.per_page = per_page;
    }
    param.mat = param.mat.join(',');
    localStorage.returnUrl = baseUrl + '?' + $.param(param);
    return baseUrl + '?' + $.param(param);
  }

  function isStockValue(value) {
    return ('0' === value || '1' === value);
  }

  // for single product page:
  let $backToProducts = $('#back-to-products');
  if ($backToProducts.length > 0) {
    $backToProducts.on('click', function(e){
      e.preventDefault();
      if (localStorage.returnUrl !== 'undefined' && typeof localStorage.returnUrl !== undefined) {
        window.location = localStorage.returnUrl;
      } else {
        window.location = e.currentTarget.href;
      }
    });
  }

  let $liLang = $('.wpml-ls-link');
  $liLang.on('click', function (e) {
    localStorage.returnUrl = 'undefined';
  });
});