// Font awesome implementation
// @doc https://fontawesome.com/how-to-use/on-the-web/referencing-icons/basic-use

import { library, dom } from '@fortawesome/fontawesome-svg-core';

import {
    faChevronLeft,
    faChevronRight,
    faBox,
    faRulerCombined,
    faSearch,
    faAngleDoubleDown,
} from '@fortawesome/free-solid-svg-icons';

import {
    faInstagram,
    faFacebookSquare,
    faLinkedin,
    faPinterestSquare,
    faTwitterSquare,
    faYoutubeSquare
} from '@fortawesome/free-brands-svg-icons';

// We are only using the icons we want to reduce compiled size
library.add(
    faInstagram,
    faFacebookSquare,
    faLinkedin,
    faPinterestSquare,
    faTwitterSquare,
    faYoutubeSquare,
    faChevronLeft,
    faChevronRight,
    faBox,
    faRulerCombined,
    faSearch,
    faAngleDoubleDown
);

// Replace any existing <i> tags with <svg> and set up a MutationObserver to
// continue doing this as the DOM changes.
dom.watch();