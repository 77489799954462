/** Animate elements into view
 *  in conjunction with classes defined in _helpers.scss
 *  adapted from: https://www.sitepoint.com/scroll-based-animations-jquery-css3/
 */

$(function () {

    // select all elements that need to be animated
    let $animatedElements = $( '.will-animate' );
    // on their parent, hide overflow to avoid ugly horizontal scroll
    $animatedElements.parent().addClass( 'overflow-hidden' );

    // on scroll: check their position and add animation classes accordingly
    $( window ).on( 'scroll resize load', function () {
        $animatedElements.each( function () {
            if ( checkIfVisible( this ) ) {
                $( this ).addClass( 'animated' );
            } else {
                $( this ).removeClass( 'animated' );
            }
        });
    });

    $( window ).trigger( 'scroll' );
});

// function that checks whether an element is visible -- exported to be available for other things
export function checkIfVisible ( $element ) {
    // find window height, top, bottom
    let $windowTop = $( window ).scrollTop();
    let $windowHeight = $( window ).height();
    let $windowBottom = $windowHeight + $windowTop;

    // find element height, top, bottom
    let $elementTop = $( $element ).offset().top;
    let $elementHeight = $( $element ).outerHeight();
    let $elementBottom = $elementHeight + $elementTop;

    // return whether the element is visible
    return $elementBottom >= $windowTop && $elementTop <= $windowBottom;
}