// Font Awesome
import 'components/FontAwesome';

// bootstrap separated modules instead of the whole bootstrap js library
import 'bootstrap/js/dist/collapse';
import 'bootstrap/js/dist/dropdown';
import 'bootstrap/js/dist/carousel';
import 'bootstrap/js/dist/tab';

// Animations on scroll
import 'components/AnimateOnScroll';

// CounterUp
import 'countup.js';
import 'components/CountUp';

// Slick Carousel
import 'components/SlickSlider';

// Button that toggles show/hide
import 'components/ShowHideButton';

// manage form for offers
import 'components/FormOffer';

// manage products page
import 'components/Products';

// Sticky tabs for Bootstrap tabs
import 'components/StickyTabs';